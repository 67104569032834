import React, { memo, useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import { useAppSelector } from '../hooks';
import { selectCountryReports } from '../store/database';
import {
  colorBlue,
  colorBlueLight,
  colorRed,
} from '../gatsby-theme-material-ui-top-layout/theme';
import { colors, Typography } from '@mui/material';
import { withPrefix } from 'gatsby';
import { Box } from '@mui/system';
import { geoCentroid, geoBounds } from 'd3-geo';
import { fontSizeBase } from '../gatsby-theme-material-ui-top-layout/theme';

interface InteractiveMapProps {
  setSelectedCountryCode: (a: string) => void;
}

const InteractiveMap = ({ setSelectedCountryCode }: InteractiveMapProps) => {
  const { locale } = useAppSelector((state) => state.i18n);
  const countryReports = useAppSelector(selectCountryReports);
  const [selectedGeoKey, setSelectedGeoKey] = useState<string | undefined>(
    undefined
  );

  return (
    <>
      <ComposableMap
        data-tip=""
        projection="geoMercator"
        height={850}
        projectionConfig={{ center: [20.46, 6.96], scale: 550 }}
        style={{ marginTop: '-2rem', paddingBottom: '2rem' }}
      >
        <pattern
          id="diagonalHatch"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
        >
          <path
            d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2"
            style={{ stroke: colors.grey[500], strokeWidth: 1 }}
          />
        </pattern>
        <Geographies
          geography={withPrefix(
            '/assets/ne_50m_admin_0_breakaway_disputed_areas_africa.json'
          )}
        >
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={`disputed-${geo.rsmKey}`}
                geography={geo}
                tabIndex={-1}
                style={{
                  default: {
                    fill: 'url(#diagonalHatch)',
                  },
                }}
              />
            ))
          }
        </Geographies>
        <Geographies
          geography={withPrefix('/assets/ne_50m_admin_0_countries_africa.json')}
        >
          {({ geographies }) =>
            geographies.map((geo) => {
              const countryCode = geo.properties.ISO_A2.toLowerCase();
              const hasCountryReport =
                countryReports.findIndex(
                  (x) => x.countryCode === countryCode
                ) >= 0;

              const onMouseEnter = () => setSelectedGeoKey(geo.rsmKey);
              const onMouseLeave = () => setSelectedGeoKey(undefined);

              return (
                <Geography
                  key={`country-${geo.rsmKey}`}
                  geography={geo}
                  onClick={() => setSelectedCountryCode(countryCode)}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  tabIndex={hasCountryReport ? 0 : -1}
                  style={{
                    default: {
                      fill: hasCountryReport ? colorBlueLight : 'transparent',
                      stroke: colors.common.black,
                      strokeWidth: '1px',
                      outline: 'none',
                    },
                    hover: {
                      fill: hasCountryReport ? colorBlue : 'transparent',
                      cursor: hasCountryReport ? 'pointer' : '',
                      stroke: colors.common.black,
                      strokeWidth: '1px',
                    },
                    pressed: {
                      fill: hasCountryReport ? colorBlue : 'transparent',
                      outline: 'none',
                      stroke: colors.common.black,
                      strokeWidth: '1px',
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
        <Geographies
          geography={withPrefix('/assets/ne_50m_admin_0_countries_africa.json')}
        >
          {({ geographies }) =>
            geographies.map((geo) => {
              const countryCode = geo.properties.ISO_A2.toLowerCase();
              const hasCountryReport =
                countryReports.findIndex(
                  (x) => x.countryCode === countryCode
                ) >= 0;

              const onMouseEnter = () => setSelectedGeoKey(geo.rsmKey);
              const onMouseLeave = () => setSelectedGeoKey(undefined);

              const centroid = geoCentroid(geo);
              const bounds = geoBounds(geo);
              const [lonBottomLeft, lonBottomRight] = bounds[0];
              const [lonTopRight, latTopRight] = bounds[1];

              const markerCoordinates: [number, number] = [
                centroid[0],
                latTopRight,
              ];

              return hasCountryReport && selectedGeoKey === geo.rsmKey ? (
                <Marker
                  key={`marker-${geo.rsmKey}`}
                  coordinates={markerCoordinates}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  z={1000}
                >
                  <defs>
                    <filter x="-0.05" y="0" width="1.1" height="1" id="solid">
                      <feFlood floodColor="white" result="bg" />
                      <feMerge>
                        <feMergeNode in="bg" />
                        <feMergeNode in="SourceGraphic" />
                      </feMerge>
                    </filter>
                  </defs>
                  <text
                    filter="url(#solid)"
                    textAnchor="middle"
                    fill={hasCountryReport ? colorRed : colors.common.black}
                    fontFamily="Calibre"
                    fontWeight="700"
                    y="-10"
                  >
                    {geo.properties[`name_${locale}`.toUpperCase()]}
                  </text>
                </Marker>
              ) : null;
            })
          }
        </Geographies>
      </ComposableMap>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="caption">
          Made with{' '}
          <a
            href="https://www.naturalearthdata.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            Natural Earth
          </a>{' '}
          map data.
        </Typography>
      </Box>
    </>
  );
};

export const MemoizedInteractiveMap = memo(InteractiveMap);
