import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Autocomplete, TextField } from '@mui/material';
import { MemoizedInteractiveMap } from './InteractiveMap';
import { useAppSelector } from '../hooks';
import { selectCountryReports } from '../store/database';
import { createLocalizedPath } from '../lib/i18n';

export const CountryReportSelection = () => {
  const countryReports = useAppSelector(selectCountryReports);

  const { locale, defaultLocale } = useAppSelector((state) => state.i18n);

  const countryReportsLocalized = countryReports
    .map(({ countryCode, translations }) => {
      const translation =
        translations.find((x) => x.locale === locale);

      return translation
        ? {
            countryCode,
            locale: translation.locale,
            slug: translation.slug,
            title: translation.title,
          }
        : undefined;
    })
    .filter((a): a is NonNullable<typeof a> => a !== undefined)
    .sort((a, b) => {
      const titleA = a.title.toUpperCase(); // ignore upper and lowercase
      const titleB = b.title.toUpperCase(); // ignore upper and lowercase
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

  const [selectedCountryReportLocalized, setSelectedCountryReportLocalized] =
    useState<typeof countryReportsLocalized[0] | null>(null);

  if (selectedCountryReportLocalized !== null) {
    const path = createLocalizedPath(
      [selectedCountryReportLocalized.slug],
      selectedCountryReportLocalized.locale,
      defaultLocale
    );
    navigate(path);
  }

  return (
    <>
      <Autocomplete
        disablePortal
        id="country-reports-select"
        options={countryReportsLocalized}
        sx={{ fontFamily: 'Calibre', width: '100%' }}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              locale === 'en' ? 'Select a country' : 'Sélectionnez un pays'
            }
            variant="standard"
          />
        )}
        onChange={(_event, value) => {
          setSelectedCountryReportLocalized(value);
        }}
      />
      <MemoizedInteractiveMap
        setSelectedCountryCode={(a) =>
          setSelectedCountryReportLocalized(
            countryReportsLocalized.find((b) => b.countryCode === a) ?? null
          )
        }
      />
    </>
  );
};
