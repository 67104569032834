import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageLayout } from '../components/PageLayout';
import { ContentHtml } from '../components/ContentHtml';
import { HomepageQuery, HomepageQueryVariables } from '../../graphql-types';
import { CountryReportSelection } from '../components/CountryReportSelection';
import { useAppDispatch } from '../hooks';
import { setCountryReports } from '../store/database';
import { setDefaultLocale, setLocale } from '../store/i18n';
import { Head } from '../components/Head';
import { Header } from '../components/Header';
import { ContentContainer } from '../components/ContentContainer';
import { Box } from '@mui/material';
import { countryReportTranslations } from '../cms/collections/countryReportTranslations';
import { mapTranslations } from '../lib/i18n';
import { GlobalStyles } from '../components/GlobalStyles';

export const homepageQuery = graphql`
  query Homepage($id: String!, $pageTitle: String!) {
    siteSettings: file(
      sourceInstanceName: { eq: "settings" }
      name: { eq: "site" }
    ) {
      childMarkdownRemark {
        frontmatter {
          defaultLocale
        }
      }
    }
    pageTranslation: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        locale
      }
    }
    pageTranslations: allFile(
      filter: {
        childMarkdownRemark: { frontmatter: { pageTitle: { eq: $pageTitle } } }
        sourceInstanceName: { eq: "pageTranslations" }
        extension: { eq: "md" }
      }
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            locale
          }
        }
      }
    }
    languages: allLanguagesYaml {
      nodes {
        locale
      }
    }
    countryReports: allFile(
      filter: {
        sourceInstanceName: { eq: "countryReports" }
        extension: { eq: "md" }
      }
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            countryCode
          }
        }
      }
    }
    countryReportTranslations: allFile(
      filter: {
        sourceInstanceName: { eq: "countryReportTranslations" }
        extension: { eq: "md" }
      }
    ) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            locale
            countryCode
            title
          }
        }
      }
    }
  }
`;

export const HomepageTemplate = ({
  title,
  content,
}: {
  title: string;
  content: JSX.Element;
}) => (
  <>
    <ContentContainer>
      <Box sx={{ marginBottom: '1rem' }}>{content}</Box>
      <CountryReportSelection />
    </ContentContainer>
  </>
);

const Homepage = ({
  data,
  location,
}: PageProps<HomepageQuery, HomepageQueryVariables>) => {
  const dispatch = useAppDispatch();

  const {
    html,
    frontmatter: { title, locale },
  } = data.pageTranslation;

  const pathsByLocale = new Map(
    data.pageTranslations.nodes.map(
      ({ childMarkdownRemark: { frontmatter } }) => [frontmatter.locale, '']
    )
  );
  dispatch(setLocale(locale));
  const defaultLocale =
    data.siteSettings.childMarkdownRemark.frontmatter.defaultLocale;
  dispatch(setDefaultLocale(defaultLocale));

  const countryReports = mapTranslations({
    defaultLocale,
    locales: data.languages.nodes.map(({ locale }) => locale),
    data: data.countryReports.nodes,
    translations: data.countryReportTranslations.nodes,
    isTranslationPredicate: (value, translation, locale) => {
      const {
        childMarkdownRemark: {
          frontmatter: { countryCode: itemCountryCode },
        },
      } = value;
      const {
        childMarkdownRemark: {
          frontmatter: {
            countryCode: translationCountryCode,
            locale: translationLocale,
          },
        },
      } = translation;
      return (
        translationCountryCode === itemCountryCode &&
        translationLocale === locale
      );
    },
  }).map(({ value, translations }) => {
    const {
      childMarkdownRemark: {
        frontmatter: { countryCode },
      },
    } = value;
    return {
      countryCode,
      translations: translations.map(({ locale, translation }) => {
        const {
          childMarkdownRemark: {
            fields: { slug },
            frontmatter: { title },
          },
        } = translation;
        return {
          locale,
          title,
          slug,
        };
      }),
    };
  });

  dispatch(setCountryReports(countryReports));

  const currentPath = location.pathname;

  return (
    <>
      <GlobalStyles />
      <Head pageTitle={title} currentPath={currentPath} />
      <Header currentPath={currentPath} pathsByLocale={pathsByLocale} />
      <PageLayout>
        <HomepageTemplate title={title} content={<ContentHtml html={html} />} />
      </PageLayout>
    </>
  );
};

export default Homepage;
